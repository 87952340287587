import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { connect } from "react-redux";
import { notification } from "antd";

function Layout({ children, alerts }) {
  useEffect(() => {
    alerts &&
      alerts.length > 0 &&
      alerts.forEach((alert) => {
        notification[alert.type]({
          message: alert.msg,
          key: alert.id,
        });
      });
  }, [alerts]);

  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => ({
  alerts: state.alerts,
});

export default connect(mapStateToProps, null)(Layout);
