import * as types from "../types";

const initialState = {
  nationality: null,
  selected: null,
  licence: null,
  fetching: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.SET_APPLICATION_DATA_SUCCESS:
      return {
        ...state,
        nationality: payload.natioanlity,
        selected: payload.selected,
        licence: payload.licence,
        fetching: false,
      };

    case types.SET_APPLICAION_DATA_FETCHING:
      return {
        ...state,
        fetching: true,
      };

    case types.SET_APPLICATION_DATA_FAILURE:
      return {
        ...state,
        nationality: null,
        selected: null,
        licence: null,
        fetching: false,
      };
    default:
      return state;
  }
}
