import axios from 'axios';

const currentVersion = '21';

export const checkVersion = async () => {
  try {
    const res = await axios.get('/version');

    if (res.status !== 200) {
      return;
    }

    if (res.data !== currentVersion) {
      window.location.reload();
    }

    console.log('V:', res.data);
  } catch (err) {
    console.error(err);
  }
};

export default checkVersion;
