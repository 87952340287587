import { combineReducers } from "redux";
import alerts from "./alerts";
import user from "./user";
import licences from "./licences";
import application from "./application";
import nationalities from "./nationalities";

export default combineReducers({
  alerts,
  user,
  licences,
  nationalities,
  application,
});
