import React, { useEffect, useState } from "react";
import { Row, Col, Table, Tag, Result } from "antd";
import axios from "axios";
import Layout from "../components/Layout";
import { Link } from "react-router-dom";
import Moment from "react-moment";

function Dashboard() {
  const [applications, setApplications] = useState([]);

  useEffect(() => {
    const getApplications = async () => {
      const res = await axios.get("/application/user/appliacations");
      setApplications(res.data);
    };
    getApplications();
  }, []);

  const getColor = (status) => {
    if (status === "in progress") return "orange";
    if (status === "rejected") return "red";
    if (status === "issued") return "green";
  };

  const columns = [
    {
      title: "Application ID",
      dataIndex: "_id",
      key: "_id",
    },
    {
      title: "Licence Type",
      dataIndex: "licence",
      key: "licence",
      render: (licence) => <span>{licence.type}</span>,
    },
    {
      title: "Applicant",
      render: (licence) => (
        <span style={{ textTransform: "capitalize" }}>
          {licence.personalInfo.firstName} {licence.personalInfo.surName}
        </span>
      ),
    },
    {
      title: "Date Applied",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => <Moment format={"DD/MM/YYYY hh:mm:ss A"} date={date} />,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color={getColor(status)} key={status}>
          {status.toUpperCase()}
        </Tag>
      ),
    },
  ];

  return (
    <Layout>
      <section className="d-single">
        <div className="container">
          <Row justify="center">
            <Col xs={24}>
              <h2>Application History</h2>
              {applications && applications.length > 0 ? (
                <>
                  <Row gutter={[0, 32]}>
                    <Col>
                      <h4>Review your Submitted Applications</h4>
                    </Col>
                  </Row>
                  <Row gutter={[0, 32]}>
                    <Col xs={24}>
                      <Table
                        columns={columns}
                        dataSource={applications}
                        rowKey={"_id"}
                        bordered
                        scroll="scroll"
                        top="none"
                        bottom="none"
                      />
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row style={{ paddingTop: "50px" }} justify="center">
                    <Col xs={24} lg={12}>
                      <Result
                        icon={
                          <img
                            src="/fishing.jpg"
                            className="img-fluid"
                            alt="No Applications Found"
                          />
                        }
                        title="No Applications Found"
                        subTitle="Hi, It looks like you have not applied for a licence at the moment."
                        extra={
                          <Link
                            className="btn btn-primary"
                            to="/application"
                            style={{ margin: "1rem auto" }}
                          >
                            Apply Now
                          </Link>
                        }
                      />
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </div>
      </section>
    </Layout>
  );
}

export default Dashboard;
