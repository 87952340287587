import React, { useState } from "react";
import Layout from "../components/Layout";
import { connect } from "react-redux";
import { login } from "../redux/actions/user";
import { Row, Col, Input, Select, Checkbox, Button, notification } from "antd";
import { Link } from "react-router-dom";

function Login({ isFetching, login, history }) {
  const [email, setEmail] = useState({
    email: "",
  });
  const [checked, setChecked] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (checkEmailAddress()) {
      const emailAddress = email.email;
      login(emailAddress, history);
    } else {
      notification.error({
        message: "Invalid Email Address",
        description:
          "Please enter a valid email address. Thanks",
      });
    }
  };

  const checkEmailAddress = () => {
    const regex = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    return email.email.match(regex)
  }

  return (
    <Layout>
      <section className="single">
        <div className="container">
          <Row justify="center">
            <Col xs={24} lg={12} xl={10}>
              <h3 style={{ fontWeight: "700" }}>Enter your email address</h3>
              <form onSubmit={handleSubmit}>
                <Input.Group style={{ margin: "1rem 0" }}>
                  <Row gutter={18}>
                      <input
                        type="email"
                        placeholder="Email"
                        value={email.email}
                        required
                        onChange={(e) =>
                          setEmail({ ...email, email: e.target.value })
                        }
                        size="large"
                      />
                  </Row>
                </Input.Group>
                <Checkbox
                  checked={checked}
                  onChange={(e) => setChecked(e.target.checked)}
                  size="large"
                >
                  I have read and agree to the {"  "}
                  <Link className="link" to="/terms">
                    Terms and Conditions
                  </Link>
                </Checkbox>
                <Row justify="end" style={{ marginTop: "2rem" }}>
                  <Col span={12}>
                    <Button
                      className="btn btn-block btn-primary"
                      htmlType="submit"
                      disabled={!checked}
                      loading={isFetching}
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </div>
      </section>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  isFetching: state.user.fetching,
});

export default connect(mapStateToProps, { login })(Login);
