import * as types from "../types";

const initialState = [];

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_LICENCES_SUCCESS:
      return payload;

    case types.GET_LICENCES_FAILURE:
      return [];

    default:
      return state;
  }
}
