import React from "react";
import { Space } from "antd";
import { Link } from "react-router-dom";

function Footer() {
  const year = new Date().getFullYear();
  return (
    <footer>
      <div className="container">
        <Space>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/cookie-policy">Cookie Policy</Link>
        </Space>
        <span>&copy; {year} HM Government of Gibraltar</span>
      </div>
    </footer>
  );
}


export default Footer;
