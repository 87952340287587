import React, { useState } from "react";
import Layout from "../components/Layout";
import { Row, Col, Form, Upload, Button, message, notification } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { LeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import axios from "axios";

function UploadDocuments({ match, history }) {
  const [documents, setDocuments] = useState([]);
  const [fetching, setFetching] = useState(false);

  const props = {
    name: "document",
    action: "https://api-fishing.herokuapp.com/upload",
    onChange(info) {
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        setDocuments([...documents, info.file.response.location]);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const handleSubmit = async () => {
    setFetching(true);
    //set api config
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    //stringigy the payload
    const body = JSON.stringify({ documents });
    //get user id from params
    const userID = match.params.ID;
    //make the call
    try {
      await axios.put(`/application/${userID}`, body, config);
      /*
      let emailData = {
        time: response.data.createdAt,
        applicationID: response.data._id,
        licenceType: "B2 Temporary Recreational - Rods and Line (Sea)",
        ...response.data,
      };
      emailData.companyInfo = null;
      //send email to admin
      await axios.post("/mail", JSON.stringify(emailData), config);
      */
      notification.success({
        message: "Thank you for verifying your documents!",
      });
      setFetching(false);
      history.push("/");
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => {
          notification.error({
            message: "Unable to submit the documents.",
            description: error.msg,
          });
        });
      }
      setFetching(false);
    }
  };

  return (
    <Layout>
      <section className="single">
        <div className="container">
          <Row gutter={[0, 32]}>
            <Col xs={24}>
              <Link to="/" className="back-link">
                <LeftOutlined />
                Back
              </Link>
            </Col>
          </Row>
          <Row justify="center">
            <Col xs={24} lg={12} xl={10}>
              <h4 style={{ marginBottom: "30px" }}>
                Please attach the following documents
              </h4>
              <Form layout="vertical" onFinish={handleSubmit}>
                <Row>
                  <Col xs={24}>
                    <Form.Item
                      label="Passport Size Photo"
                      name="Passport Size Photo"
                      rules={[
                        {
                          required: true,
                          message: "Please attach a document",
                        },
                      ]}
                    >
                      <Upload {...props}>
                        <Button>
                          <UploadOutlined /> Click to Upload
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      label="Identification Card or Passport Photo"
                      name="Identification Card or Passport Photo"
                      rules={[
                        {
                          required: true,
                          message: "Please attach a document",
                        },
                      ]}
                    >
                      <Upload {...props}>
                        <Button>
                          <UploadOutlined /> Click to Upload
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      label="Vessel Photo"
                      name="Vessel Photo"
                      rules={[
                        {
                          required: true,
                          message: "Please attach a document",
                        },
                      ]}
                    >
                      <Upload {...props}>
                        <Button>
                          <UploadOutlined /> Click to Upload
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      label="Vessel Documentaion"
                      name="Vessel Documentaion"
                      rules={[
                        {
                          required: true,
                          message: "Please attach a document",
                        },
                      ]}
                    >
                      <Upload {...props}>
                        <Button>
                          <UploadOutlined /> Click to Upload
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      label="Attach Any Other Documents"
                      name="any documents"
                      rules={[
                        {
                          required: false,
                          message: "Please attach a document",
                        },
                      ]}
                    >
                      <Upload {...props}>
                        <Button>
                          <UploadOutlined /> Click to Upload
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="center" style={{ padding: "30px 0" }}>
                  <Col xs={24}>
                    <Button
                      htmlType="submit"
                      className="btn btn-block btn-primary"
                      loading={fetching}
                    >
                      Compelete Process
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>
      </section>
    </Layout>
  );
}

export default UploadDocuments;
