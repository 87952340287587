import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { connect } from "react-redux";
import { Row, Col, Button, notification } from "antd";
import ReactCodeInput from "react-verification-code-input";
import { Link } from "react-router-dom";
import store from "../redux/store";
import { authenticateUser, resend } from "../redux/actions/user";

function Verify({ resend, email, OTP, resendFetching, history }) {
  const [code, setCode] = useState("");
  const [fetching, setFetcing] = useState(false);
  const [counter, setCounter] = useState(60);
  const [hasClicked, setClicked] = useState(false);

  useEffect(() => {
    startTimer();
  }, []);

  const onVerifyHanlder = (e) => {
    setFetcing(true);
    if (code.match(OTP)) {
      store.dispatch(authenticateUser(true, history));
    } else {
      notification.error({ message: "Invalid code. Try again later" });
    }
    setFetcing(false);
  };

  const handleResend = () => {
    resend(email);
    startTimer();
  };

  if (counter === 0) {
    clearInterval(window.timer_interval);
    setClicked(false);
    setCounter(60);
  }

  const startTimer = () => {
    setClicked(true);
    window.timer_interval = setInterval(() => {
      setCounter((prevValue) => prevValue - 1);
    }, 1000);
  };

  return (
    <Layout>
      <section className="single">
        <div className="container">
          <Row justify="center">
            <Col xs={24} lg={12} xl={10}>
              <h3 style={{ fontWeight: "700" }}>{email && email}</h3>
              <Link className="danger-link" to="/login">
                Wrong email address?
              </Link>
              <h6 style={{ fontWeight: 300, margin: "1rem 0" }}>
                Enter the 6-digit code that we have sent to your email address.
              </h6>
              <ReactCodeInput
                className="OTP-input"
                onComplete={(e) => setCode(e)}
                onChange={(e) => setCode(e)}
              />
              <h6 style={{ fontWeight: 300, margin: "1rem 0" }}>
                You must wait at least 60 seconds before resending another code.
              </h6>
              <Row gutter={32} className="m-0" style={{ marginTop: "2rem" }}>
                <Col span={12}>
                  <Button
                    className="btn btn-block btn-secondary"
                    loading={resendFetching}
                    onClick={handleResend}
                    disabled={hasClicked}
                  >
                    {hasClicked ? `Please Wait ${counter}` : "Resend Code"}
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    className="btn btn-block btn-primary"
                    onClick={onVerifyHanlder}
                    loading={fetching}
                    disabled={code.length !== 6}
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </section>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  email: state.user.email,
  resendFetching: state.user.resendFetching,
  OTP: state.user.code,
});

export default connect(mapStateToProps, { authenticateUser, resend })(Verify);
