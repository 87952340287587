import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";

import HomePage from "./pages/Home";
import UsefulNumbers from "./pages/UserFulNumbers";
import Guide from "./pages/Guide";
import LoginPage from "./pages/Login";
import VerifyPage from "./pages/Verify";
import DashboardPage from "./pages/Dashboard";
import ApplicationPage from "./pages/Application";
import ApplyApplicaion from "./pages/ApplyApplication";
import Cookie from "./pages/Cookie";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import UploadDocuments from "./pages/UploadDocuments";

function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/useful-numbers" component={UsefulNumbers} />
        <Route exact path="/guide" component={Guide} />
        <Route exact path="/cookie-policy" component={Cookie} />
        <Route exact path="/privacy-policy" component={Privacy} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/verify" component={VerifyPage} />
        <Route exact path="/upload-documents/:ID" component={UploadDocuments} />
        <PrivateRoute exact path="/dashboard" component={DashboardPage} />
        <PrivateRoute exact path="/application" component={ApplicationPage} />
        <PrivateRoute
          exact
          path="/application/apply"
          component={ApplyApplicaion}
        />
      </Switch>
    </BrowserRouter>
  );
}

export default Router;
