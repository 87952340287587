import React, { useState, useEffect } from "react";
import { Select, Row, Col, Result, Button, notification } from "antd";
import { connect } from "react-redux";
import { getLicences } from "../redux/actions/licences";
import { getNationalities } from "../redux/actions/nationalities";
import Layout from "../components/Layout";
import { getLicence } from "../redux/actions/application";
import { checkIfSubmissionDateAllowed } from '../utils/helpers';

function Application({
  getNationalities,
  getLicences,
  nationalities,
  licences,
  getLicence,
  fetching,
  history,
}) {
  const [selected, setSelected] = useState("");
  const [nationality, setNationality] = useState("");

  const handleNatioanlityChanged = (e) => {
    getLicences(e);
    setNationality(e);
  };

  const handleOnFinish = (e) => {
    e.preventDefault();

    if (selected && nationality) {

      const msg = checkIfSubmissionDateAllowed();
      if(msg !== "") {
        notification.error({ message: msg });
      } else {
        getLicence(nationality, selected, history);
      }
    } else {
      notification.error({ message: "Please Select a Licence" });
    }  
  };

  useEffect(() => {
    setSelected("");
    setNationality("");
    getNationalities();
    getLicences();
  }, []);

  return (
    <Layout>
      <section className="d-single">
        <div className="container">
          <Row gutter={[32, 32]} justify="center">
            <Col xs={24} lg={12}>
              <h2 className="text-center">New Licence Application</h2>
              <form onSubmit={handleOnFinish} style={{ marginTop: "45px" }}>
                <Row gutter={[0, 16]}>
                  <Col xs={24}>
                    <h5 style={{ margin: "1.5rem 0" }}>
                      Step 1. Select Place of Residence
                    </h5>
                    <Select
                      name="nationality"
                      showSearch
                      onChange={handleNatioanlityChanged}
                      placeholder="Place of Residence"
                      style={{ width: "100%" }}
                    >
                      {nationalities.map((nationality, i) => (
                        <Select.Option value={nationality.name} key={i}>
                          {nationality.name.toUpperCase()}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
                {licences && licences.length > 0 && (
                  <>
                    <Row gutter={[0, 16]}>
                      <Col xs={24}>
                        <h5 style={{ margin: "1.5rem 0" }}>
                          Step 2. Select a Licence Type
                        </h5>
                        <Select
                          name="licence"
                          showSearch
                          onChange={(e) => setSelected(e)}
                          placeholder="Please Select a Licence"
                          style={{ width: "100%" }}
                        > 
                          {licences.map((value, index) => (
                            <Select.Option value={value._id} key={index}>
                              {value.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "45px" }}>
                      <Button
                        htmlType="submit"
                        className="btn btn-primary btn-block"
                        loading={fetching}
                        
                      >
                        Next
                      </Button>
                    </Row>
                  </>
                )}
              </form>
              {licences && !licences.length > 0 && nationality && (
                <Row justify="center">
                  <Col span={24} className="text-center">
                    <Result
                      icon={
                        <img
                          src="https://cdn140.picsart.com/283846118042211.png?type=webp&to=min&r=1024"
                          style={{ width: "350px", height: "auto" }}
                          alt="No Licences Found"
                        />
                      }
                      title="No Licences Found"
                      subTitle="Hi! It looks like there are no licences available for your place of residence at the moment."
                    />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </div>
      </section>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  licences: state.licences,
  nationalities: state.nationalities,
  fetching: state.application.fetching,
});

export default connect(mapStateToProps, {
  getLicences,
  getNationalities,
  getLicence,
})(Application);
