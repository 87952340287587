import * as types from "../types";
import { setAlert } from "./alerts";
import axios from "axios";

export const getLicence = (natioanlity, selected, history) => async (
  dispatch
) => {
  dispatch({
    type: types.SET_APPLICAION_DATA_FETCHING,
  });

  //make the call
  try {
    const res = await axios.get(`/manage/licence/${selected}`);
    const payload = {
      natioanlity: natioanlity,
      selected: selected,
      licence: res.data,
    };
    dispatch({
      type: types.SET_APPLICATION_DATA_SUCCESS,
      payload,
    });
    history.push("/application/apply");
  } catch (err) {
    dispatch({
      type: types.SET_APPLICATION_DATA_FAILURE,
    });
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => {
        dispatch(setAlert(error.msg, "error"));
      });
    }
  }
};
