import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Space, Drawer } from "antd";
import { NavLink } from "react-router-dom";
import { logout } from "../redux/actions/user";
import { MenuOutlined } from "@ant-design/icons";

import "../styles/Header/Header.css";
import { useLocation } from 'react-router-dom';

function Header({ isAuth, logout }) {
  const [visible, setVisible] = useState(false);

  const toggle = () => {
    setVisible(!visible);
  };

  const [location, _] = useState(useLocation())

  return (
    <>
      <header>
        <div className="container">
          <Row align="middle" style={{ width: "100%" }}>
            <Col xs={12} lg={12}>
              <NavLink to="/">
                <img src="/logo.png" alt="HMBOG Fishing Licences Application" />
              </NavLink>
            </Col>
            <Col xs={0} lg={12}>
              <div className="menu">
                <Space size="large">
                  <NavLink to="/guide">USER GUIDE</NavLink>
                  <NavLink to="/useful-numbers">USEFUL CONTACTS</NavLink>
                </Space>
              </div>
            </Col>
            <Col xs={12} lg={0} style={{ textAlign: "right" }}>
              <MenuOutlined
                style={{ color: "white", fontSize: "20px" }}
                onClick={toggle}
              />
            </Col>
          </Row>
        </div>
      </header>
      {isAuth && !location.pathname.includes("/verify") && !location.pathname.includes("/login") && (
        <nav>
          <div className="container">
            <div className="menu">
              <NavLink to="/dashboard">Home</NavLink>
              <NavLink to="/application">New Licence</NavLink>
              <NavLink to="#" onClick={logout}>
                Log Out
              </NavLink>
            </div>
          </div>
        </nav>
      )}
      <Drawer
        placement="right"
        closable={true}
        visible={visible}
        onClose={toggle}
        drawerStyle={{ backgroundColor: "black" }}
        headerStyle={{ color: "white" }}
      >
        <ul className="menu-vertical">
          <li>
            <NavLink to="/guide">USER GUIDE</NavLink>
          </li>
          <li>
            <NavLink to="/useful-numbers">USEFUL CONTACTS</NavLink>
          </li>
        </ul>
      </Drawer>
    </>
  );
}

const mapStateToProps = (state) => ({
  isAuth: state.user.auth,
});

export default connect(mapStateToProps, { logout })(Header);
