import * as types from "../types";
import { setAlert } from "./alerts";
import axios from "axios";
import { setUserToken } from "../../utils/setToken";

export const authenticateUser = (redirect = false, history = null) => async (dispatch) => {

  if (localStorage.getItem("userToken")) {
    setUserToken(localStorage.getItem("userToken"));
  }

  try {
    const res = await axios.get("/user/auth");
    dispatch({
      type: types.USER_AUTH_SUCCESS,
      payload: res.data,
    });
    if(redirect){
      history.push("/dashboard")
    }
  } catch (err) {
    dispatch({
      type: types.USER_AUTH_FAILURE,
    });
    const errors = err.resonse && err.response.data.errors;
    if (errors) {
      errors.forEach((error) => {
        dispatch(setAlert(error.msg, "error"));
      });
    }
  }
};

export const login = (email, history) => async (dispatch) => {
  //dispatch the is_fetching action
  dispatch({
    type: types.IS_FETCHING,
  });
  //set api config
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  //stringify the payload
  const body = JSON.stringify({ email });
  try {
    const res = await axios.post("/user/register", body, config);
    dispatch({
      type: types.USER_LOGIN_SUCCESS,
      payload: { ...res.data, email },
    });
    history.push("/verify");
  } catch (err) {
    dispatch({
      type: types.USER_LOGIN_FAILURE,
    });
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => {
        dispatch(setAlert(error.msg, "error"));
      });
    }
  }
};

export const logout = () => (dispatch) => {
  dispatch({
    type: types.USER_LOGGED_OUT,
  });
};

export const resend = (email) => async (dispatch) => {
  dispatch({
    type: types.RESEND_FETCHING,
  });
  //set api config
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  //stringigy the payload
  const body = JSON.stringify({ email });
  try {
    const res = await axios.put("/user/register", body, config);
    dispatch({
      type: types.USER_RESEND_SUCCESS,
      payload: res.data.code,
    });
    dispatch(setAlert("Code Sent Via Email.", "success"));
  } catch (err) {
    dispatch({
      type: types.USER_RESEND_FAILURE,
    });
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => {
        dispatch(setAlert(error.msg, "error"));
      });
    }
  }
};
