import * as types from "../types";
import { setAlert } from "./alerts";
import axios from "axios";

export const getNationalities = () => async (dispatch) => {

  try {
    const res = await axios.get("/manage/nationalities");
    dispatch({
      type: types.GET_NATIONALITIES_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: types.GGET_NATIONALITIES_FAILURE,
    });
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => {
        dispatch(setAlert(error.msg, "error"));
      });
    }
  }
};
