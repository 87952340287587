import React, { useState } from "react";
import { connect } from "react-redux";
import Layout from "../components/Layout";
import {
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Button,
  notification,
  message,
  Upload,
  Space,
  Radio,
  Modal,
} from "antd";
import {
  UploadOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { checkIfSubmissionDateAllowed } from '../utils/helpers';
import { checkIfSendNotificationEmail } from '../utils/helpers';

function ApplyApplication({ history, application }) {
  const [fetching, setFetching] = useState(false);
  const [personalInfo, setPeronalInfo] = useState({
    firstName: "",
    surName: "",
    address: "",
    nationality: application.nationality,
    idNo: "",
    dob: "",
    number: "",
    email: "",
  });
  const [companyInfo, setCompany] = useState({
    companyName: "",
    companyNumber: "",
    licenseNumber: "",
    taxNumber: "",
    employees: [],
  });
  const [vesselInfo, setVessel] = useState({
    vesselName: "",
    registrationNumber: "",
    power: "",
    length: "",
  });
  const [documents, setDocuments] = useState([]);
  const [customerMessage, setCustomerMessage] = useState("");
  const [appType, setAppType] = useState("New");
  const [ageErr, setError] = useState(false);

  const props = {
    name: "document",
    multiple: false,
    action: "https://api-fishing.herokuapp.com/upload",
    onChange(info) {
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        setDocuments([...documents, info.file.response.location]);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const handlePersonalChange = (e) => {
    setPeronalInfo({ ...personalInfo, [e.target.name]: e.target.value });
  };

  const handleCompanyChange = (e) => {
    setCompany({ ...companyInfo, [e.target.name]: e.target.value });
  };

  const handleVesselChange = (e) => {
    setVessel({ ...vesselInfo, [e.target.name]: e.target.value });
  };

  const getAge = (DOB) => {
    var today = new Date();
    var birthDate = new Date(DOB);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const handleDateChange = (date, ds) => {
    if (getAge(date) >= 16) {
      setPeronalInfo({ ...personalInfo, dob: ds });
      setError(false);
    } else {
      setError(true);
    }
  };

  const handleSubmit = async (values) => {
    
    setFetching(true);

    const msg = checkIfSubmissionDateAllowed();
    if(msg !== "") {
      notification.error({ message: msg });
      setFetching(false);
      return;
    }

    let formData = {
      licence: application.selected,
      type: appType,
      message: customerMessage,
      personalInfo: personalInfo,
      companyInfo: application.licence.companyInformation ? companyInfo : null,
      vesselInfo: application.licence.vesselInformation ? vesselInfo : null,
      documents: application.licence.documentsRequired ? documents : null,
    };
    if (formData.companyInfo) {
      formData.companyInfo.employees = values.users;
    }

    //check date
    if (!ageErr) {
      //set api config
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      //stringigy the payload
      const body = JSON.stringify(formData);
      try {
        //create an application
        const appRes = await axios.post("/application", body, config);
        const appData = await appRes.data;

        //data for admin email
        const adminData = {
          time: appData.createdAt,
          licenceType: application.licence.type,
          applicationID: appData._id,
          number: appData.number,
          ...formData,
        };

        //send email to admin
        const ifEmail = checkIfSendNotificationEmail();
        if(ifEmail === true) {
          console.log('SENDING EMAIL');
          await axios.post("/mail", JSON.stringify(adminData), config);
          await axios.post("/mail/admin", JSON.stringify(adminData), config);
        }
        //await axios.post("/mail", JSON.stringify(adminData), config);
        //await axios.post("/mail/admin", JSON.stringify(adminData), config);

        //data from customer form
        const applicationData = {
          email: personalInfo.email,
          firstName: personalInfo.firstName,
          type: application.licence.type,
          id: appData._id,
          number: appData.number,
          applicationType: appData.type,
        };
        //send the email to customer
        await axios.post(
          "/mail/customer",
          JSON.stringify(applicationData),
          config
        );

        notification.success({
          message: "Thanks! We will process your licence and revert back asap.",
        });
        history.push("/dashboard");
      } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
          errors.forEach((error) => {
            notification.error({
              message: "Unable to submit the application",
              description: error.msg,
            });
          });
        }
        setFetching(false);
      }
    } else {
      notification.error({
        message: "You must be over 14 years old.",
      });
      setFetching(false);
    }
  };

  return (
    <Layout>
      <section>
        <div className="container">
          <Row justify="center">
            <Col xs={24} lg={18} xl={14}>
              <Row gutter={[0, 32]}>
                <Col xs={24}>
                  <h4 className="m-0" style={{ fontWeight: "700" }}>
                    Application for{" "}
                    {application.licence ? application.licence.type : "Licence"}
                  </h4>
                </Col>
              </Row>
              <Form
                layout="vertical"
                onFinish={handleSubmit}
                onFinishFailed={() =>
                  Modal.error({
                    title: "Please fill all the required fields.",
                  })
                }
              >
                <Row gutter={[0, 16]}>
                  <Col xs={24}>
                    <h4>Personal Information</h4>
                  </Col>
                </Row>
                <Row gutter={[0, 16]}>
                  <Col xs={24}>
                    <Form.Item
                      label="First Name"
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: "Please input a valid value!",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        name="firstName"
                        value={personalInfo.firstName}
                        onChange={handlePersonalChange}
                        placeholder="First Name"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      label="Last Name"
                      name="surName"
                      rules={[
                        {
                          required: true,
                          message: "Please input a valid value!",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        name="surName"
                        value={personalInfo.surName}
                        onChange={handlePersonalChange}
                        placeholder="Last Name"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      label="Address"
                      name="address"
                      rules={[
                        {
                          required: true,
                          message: "Please input a valid value!",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        name="address"
                        value={personalInfo.address}
                        onChange={handlePersonalChange}
                        placeholder="Address"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      label="ID Number or Passport Number"
                      name="idNo"
                      rules={[
                        {
                          required: true,
                          message: "Please input a valid value!",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        name="idNo"
                        value={personalInfo.idNo}
                        onChange={handlePersonalChange}
                        placeholder="ID Number"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      label="Contact Number"
                      name="number"
                      rules={[
                        {
                          required: true,
                          message: "Please input a valid value!",
                        },
                      ]}
                    >
                      <Input
                        type="tel"
                        name="number"
                        value={personalInfo.number}
                        onChange={handlePersonalChange}
                        placeholder="Contact Number"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      label="Contact Email"
                      name="email"
                      rules={[
                        {
                          type: "email",
                          required: true,
                          message: "Please input a valid value!",
                        },
                      ]}
                    >
                      <Input
                        type="email"
                        name="email"
                        value={personalInfo.email}
                        onChange={handlePersonalChange}
                        placeholder="Contact Email"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      label="Date of Birth"
                      name="dob"
                      rules={[
                        {
                          required: true,
                          message: "You Must be 14 Years Old",
                        },
                      ]}
                    >
                      <DatePicker
                        name="dob"
                        format="DD/MM/YYYY"
                        value={personalInfo.dob}
                        onChange={handleDateChange}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                {application.licence.companyInformation && (
                  <>
                    <Row gutter={[0, 16]}>
                      <Col xs={24}>
                        <h4>Company Information</h4>
                      </Col>
                    </Row>
                    <Row gutter={[0, 16]}>
                      <Col>
                        <Form.Item
                          label="Company Name"
                          name="companyName"
                          rules={[
                            {
                              required: true,
                              message: "Please input a valid value!",
                            },
                          ]}
                        >
                          <Input
                            type="text"
                            name="companyName"
                            value={companyInfo.companyName}
                            onChange={handleCompanyChange}
                            placeholder="Company Name"
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          label="Company Number"
                          name="companyNumber"
                          rules={[
                            {
                              required: true,
                              message: "Please input a valid value!",
                            },
                          ]}
                        >
                          <Input
                            name="companyNumber"
                            value={companyInfo.companyNumber}
                            onChange={handleCompanyChange}
                            placeholder="Company Number"
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          label="Licence Number"
                          name="licenseNumber"
                          rules={[
                            {
                              required: true,
                              message: "Please input a valid value!",
                            },
                          ]}
                        >
                          <Input
                            name="licenseNumber"
                            value={companyInfo.licenseNumber}
                            onChange={handleCompanyChange}
                            placeholder="Licence Number"
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          label="Tax Number"
                          name="taxNumber"
                          rules={[
                            {
                              required: true,
                              message: "Please input a valid value!",
                            },
                          ]}
                        >
                          <Input
                            name="taxNumber"
                            value={companyInfo.taxNumber}
                            onChange={handleCompanyChange}
                            placeholder="Tax Number"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[0, 16]}>
                      <Form.List name="users">
                        {(fields, { add, remove }) => {
                          return (
                            <div>
                              {fields.map((field, index) => (
                                <Space
                                  key={index}
                                  style={{ display: "flex", marginBottom: 8 }}
                                  align="start"
                                >
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "first"]}
                                    fieldKey={[field.fieldKey, "first"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Missing first name",
                                      },
                                    ]}
                                  >
                                    <Input placeholder="First Name" />
                                  </Form.Item>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "last"]}
                                    fieldKey={[field.fieldKey, "last"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Missing last name",
                                      },
                                    ]}
                                  >
                                    <Input placeholder="Last Name" />
                                  </Form.Item>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "dob"]}
                                    fieldKey={[field.fieldKey, "dob"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please Enter Date of Birth",
                                      },
                                    ]}
                                  >
                                    <DatePicker />
                                  </Form.Item>

                                  <MinusCircleOutlined
                                    onClick={() => {
                                      remove(field.name);
                                    }}
                                  />
                                </Space>
                              ))}

                              <Form.Item>
                                <Button
                                  type="dashed"
                                  onClick={() => {
                                    add();
                                  }}
                                  block
                                >
                                  <PlusOutlined /> Add Employee Details
                                </Button>
                              </Form.Item>
                            </div>
                          );
                        }}
                      </Form.List>
                    </Row>
                  </>
                )}
                {application.licence.vesselInformation && (
                  <>
                    <Row gutter={[0, 16]}>
                      <Col xs={24}>
                        <h4>Vessel Information</h4>
                      </Col>
                    </Row>
                    <Row gutter={[0, 16]}>
                      <Col xs={24}>
                        <Form.Item
                          label="Vessel Name"
                          name="vesselName"
                          rules={[
                            {
                              required: true,
                              message: "Please input a valid value!",
                            },
                          ]}
                        >
                          <Input
                            name="vesselName"
                            value={companyInfo.vesselName}
                            onChange={handleVesselChange}
                            placeholder="Vessel Name"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        <Form.Item
                          label="Registration Number"
                          name="registrationNumber"
                          rules={[
                            {
                              required: true,
                              message: "Please input a valid value!",
                            },
                          ]}
                        >
                          <Input
                            name="registrationNumber"
                            value={companyInfo.registrationNumber}
                            onChange={handleVesselChange}
                            placeholder="Registration Number"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        <Form.Item
                          label="Horse Power"
                          name="power"
                          rules={[
                            {
                              required: true,
                              message: "Please input a valid value!",
                            },
                          ]}
                        >
                          <Input
                            name="power"
                            value={companyInfo.power}
                            onChange={handleVesselChange}
                            placeholder="Horse Power"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        <Form.Item
                          label="Vessel Length"
                          name="length"
                          rules={[
                            {
                              required: true,
                              message: "Please input a valid value!",
                            },
                          ]}
                        >
                          <Input
                            name="length"
                            value={companyInfo.length}
                            onChange={handleVesselChange}
                            placeholder="Vessel Length"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
                <Row gutter={[0, 16]}>
                  <Col xs={24}>
                    <h4>Additional Information</h4>
                    <Form.Item
                      name="customerMessage"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input.TextArea
                        rows={4}
                        value={customerMessage}
                        onChange={(e) => setCustomerMessage(e.target.value)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                {application.licence.documentsRequired &&
                  application.licence.documentsRequired.length > 0 && (
                    <>
                      <Row gutter={[0, 16]}>
                        <Col xs={24}>
                          <h4>Please attach the following documents</h4>
                          {application.licence.documentsRequired.map(
                            (doc, index) => (
                              <Form.Item
                                key={index}
                                label={doc}
                                name={doc}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please attach a document",
                                  },
                                ]}
                              >
                                <Upload accept=".doc,.docx,.pdf,.jpg,.jpeg,.png,.bmp" {...props}>
                                  <Button>
                                    <UploadOutlined /> Click to Upload
                                  </Button>
                              </Upload>
                              </Form.Item>
                            )
                          )}
                        </Col>
                        <Col xs={24}>
                          <Form.Item
                            label="Attach Any Other Documents"
                            name="any documents"
                            rules={[
                              {
                                required: false,
                                message: "Please attach a document",
                              },
                            ]}
                          >
                            <Upload accept=".doc,.docx,.pdf,.jpg,.jpeg,.png,.bmp" {...props}>
                              <Button>
                                <UploadOutlined /> Click to Upload
                              </Button>
                            </Upload>
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}
                <Row justify="center" style={{ padding: "45px 0" }}>
                  <Col xs={24}>
                    <Button
                      htmlType="submit"
                      className="btn btn-block btn-primary"
                      loading={fetching}
                    >
                      Apply for{" "}
                      {application.licence
                        ? application.licence.type
                        : "Licence"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>
      </section>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  application: state.application,
});

export default connect(mapStateToProps, null)(ApplyApplication);
