import * as types from "../types";

const initialState = {
  data: null,
  auth: false,
  token: localStorage.getItem("userToken"),
  code: null,
  email: null,
  loading: true,
  fetching: false,
  resendFetching: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.IS_FETCHING:
      return {
        ...state,
        fetching: true,
      };

    case types.RESEND_FETCHING:
      return {
        ...state,
        resendFetching: true,
      };

    case types.USER_LOGIN_SUCCESS:
      localStorage.setItem("userToken", payload.token);
      return {
        ...state,
        token: payload.token,
        code: payload.code,
        email: payload.email,
        fetching: false,
        loading: false,
      };

    case types.USER_AUTH_SUCCESS:
      return {
        ...state,
        auth: true,
        data: payload,
        code: null,
        token: null,
        loading: false,
        fetching: false,
        email: null,
      };

    case types.USER_RESEND_SUCCESS:
      return {
        ...state,
        code: payload,
        resendFetching: false,
      };

    case types.USER_RESEND_FAILURE:
      return {
        ...state,
        resendFetching: false,
      };

    case types.USER_AUTH_FAILURE:
    case types.USER_LOGIN_FAILURE:
    case types.USER_LOGGED_OUT:
      localStorage.removeItem("userToken");
      return {
        data: null,
        auth: false,
        token: null,
        code: null,
        loading: false,
        fetching: false,
        email: null,
        resendFetching: false,
      };

    default:
      return state;
  }
}
