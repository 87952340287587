import React from "react";
import Layout from "../components/Layout";
import { Row, Col } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

function Guide() {
  return (
    <Layout>
      <section>
        <div className="container">
          <Row gutter={[0, 32]}>
            <Col xs={24}>
              <Link to="/" className="back-link">
                <LeftOutlined />
                Back
              </Link>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 style={{ margin: "0" }}>User Guide</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                The following steps will assist you and explain how to make an
                online application for a licence.
              </p>
              <ol className="guide-list">
                <li>
                  Click on "Start Now" button on the home page.
                  <img src="/s1.png" alt="Step-1" className="img-fluid" />
                </li>
                <li>
                  Enter your email address. Check the Terms and Conditions box and click on the green button
                  labelled "Next".
                  <img src="/ss-2.png" alt="Step-2" className="img-fluid" />
                </li>
                <li>
                  You will receive a 6 digit code via Email, input these digits
                  and then click on the green button labelled “Enter”.
                  <img src="/ss-3.png" alt="Step-3" className="img-fluid" />
                </li>
                <li>
                  Should you not have received the code, please verify your
                  email address and change this using the “Wrong email address?” link. If the
                  email address is correct please click on the “Resend Code” button.
                  <img src="/ss-4.png" alt="Step-4" className="img-fluid" />
                </li>
                <li>
                  You will then be redirected to the home page where you can
                  view the status of your existing applications. To apply for a
                  new licence, click on "New Licence" or "Apply Now" buttons.
                  <img src="/ss-5.png" alt="Step-5" className="img-fluid" />
                </li>
                <li>
                  To apply for a new licence first select your place of
                  residence from the drop-down list provided. You will then be
                  prompted with a list of available licences available to you
                  for application. Select the licence type you wish to apply for
                  and click on the button labelled "Next".
                  <img src="/ss-6.png" alt="Step-6" className="img-fluid" />
                </li>
                <li>
                  Enter all the requested information of the form.
                  <img src="/ss-7.png" alt="Step-7" className="img-fluid" />
                </li>
                <li>
                  Ensure you attach any requested documents.
                  <img src="/ss-8.png" alt="Step-8" className="img-fluid" />
                </li>
                <li>
                  Once you are happy with the information you have entered into
                  the form and completed all the required fields, click on the
                  “Apply” button to submit your application. You will receive an
                  email confirming your application.
                </li>
              </ol>
            </Col>
          </Row>
        </div>
      </section>
    </Layout>
  );
}

export default Guide;
