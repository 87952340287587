import React from "react";
import Layout from "../components/Layout";
import { Row, Col } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

function UserFulNumbers() {
  return (
    <Layout>
      <section className="single">
        <div className="container">
          <Row gutter={[0, 32]}>
            <Col xs={24}>
              <Link to="/" className="back-link">
                <LeftOutlined />
                Back
              </Link>
            </Col>
          </Row>
          <Row gutter={[0, 16]}>
            <Col>
              <h2>Useful Contacts</h2>
            </Col>
          </Row>
          <Row gutter={[32, 32]}>
            <Col xs={24} lg={8}>
              <h5>Telephone Consultations and Enquiries</h5>
              <h6>
                T:{" "}
                <a href="tel:+350) 20071061" className="link">
                  (+350) 20071061
                </a>
              </h6>
              <h6>
                E:{" "}
                <a
                  href="mailto:fishing.permits@gibraltar.gov.gi"
                  className="link"
                >
                  fishing.permits@gibraltar.gov.gi
                </a>
              </h6>
              <h6>
                E:{" "}
                <a
                  href="mailto:info.environment@gibraltar.gov.gi"
                  className="link"
                >
                  info.environment@gibraltar.gov.gi
                </a>
              </h6>
            </Col>
            <Col xs={24} lg={8}>
              <h5>Address</h5>
              <span>
                Department of the Environment, Sustainability, Climate Change
                and Heritage. <br /> 1B Leanse Place, 50 Town Range, Gibraltar
              </span>
            </Col>
          </Row>
        </div>
      </section>
    </Layout>
  );
}

export default UserFulNumbers;
