export const checkIfSubmissionDateAllowed = () => {

  let date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  // Not necessary for this version
  // let hour = date.getHours(); 

  // let dateIsValid = true; // FOR TESTING
   let dateIsValid = false; // FOR PRODUCTION

  if (year === 2024 && month === 5 && day >= 16 && day <= 30) {
    // Not necessary check hour for this version
    // if(day === 16 && hour < 12) {
      // STAYS FALSE
    // }
    // else {
      dateIsValid = true;
    // }
  }

    if(dateIsValid === false) {
      return "New applications are accepted from Thursday 16th May 00:00 to Thursday 30th May 23:59";
    }

    return "";
  }

  //set to true when deploying production version - FOR PRODUCTION
  export const checkIfSendNotificationEmail = () => {
    return true; // FOR PRODUCTION
    //return false; // FOR TESTING
  }
