export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const USER_AUTH_SUCCESS = "USER_AUTH_SUCCESS";
export const USER_AUTH_FAILURE = "USER_AUTH_FAILURE";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const USER_RESEND_SUCCESS = "USER_RESEND_SUCCESS";
export const USER_RESEND_FAILURE = "USER_RESEND_FAILURE";
export const IS_FETCHING = "IS_FETCHING";
export const RESEND_FETCHING = "RESEND_FETCHING";
export const GET_NATIONALITIES_SUCCESS = "GET_NATIONALITIES_SUCCESS";
export const GGET_NATIONALITIES_FAILURE = "GET_NATIONALITIES_FAILURE";
export const GET_LICENCES_SUCCESS = "GET_LICENCES_SUCCESS";
export const GET_LICENCES_FAILURE = "GET_LICENCES_FAILURE";
export const SET_APPLICATION_DATA_SUCCESS = "SET_APPLICATION_DATA_SUCCESS";
export const SET_APPLICATION_DATA_FAILURE = "SET_APPLICATION_DATA_FAILURE";
export const SET_APPLICAION_DATA_FETCHING = "SET_APPLICAION_DATA_FETCHING";
