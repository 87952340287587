import React, { useEffect } from "react";
import Router from "./Router";
import axios from "axios";
import { connect } from "react-redux";
import { authenticateUser } from "./redux/actions/user";
import { setUserToken } from "./utils/setToken";
import checkVersion from './version-check';

axios.defaults.baseURL = process.env.REACT_APP_API;

if (localStorage.getItem("userToken")) {
  setUserToken(localStorage.getItem("userToken"));
}

var frequency = 1000 * 60 * 5

checkVersion();

setInterval(() => {
  checkVersion();
}, frequency);

function App({ authenticateUser }) {
  useEffect(() => {
    authenticateUser();
  }, []);
  return <Router />;
}

export default connect(null, { authenticateUser })(App);
