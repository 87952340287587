import React from "react";
import Layout from "../components/Layout";
import { Row, Col } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

function Privacy() {
  return (
    <Layout>
      <section className="single">
        <div className="container">
          <Row gutter={[0, 32]}>
            <Col xs={24}>
              <Link to="/" className="back-link">
                <LeftOutlined />
                Back
              </Link>
            </Col>
          </Row>
          <Row gutter={[0, 16]}>
            <Col className="long-text">
              <h2>Privacy Policy</h2>
              <p>
                Your privacy is important to us. It is HM Government of
                Gibraltar's policy to respect your privacy regarding any
                information we may collect from you across our website, &nbsp;
                <a
                  href="https://fishinglicences.environment.gi"
                  className="link"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://fishinglicences.environment.gi
                </a>
                &nbsp; and other sites we own and operate.
              </p>
              <h3>Information we collect</h3>
              <h4>Log data</h4>
              <p>
                When you visit our website, our servers may automatically log
                the standard data provided by your web browser. This data is
                considered “non-identifying information”, as it does not
                personally identify you on its own. It may include your
                computer’s Internet Protocol (IP) address, your browser type and
                version, the pages you visit, the time and date of your visit,
                the time spent on each page, and other details.
              </p>
              <p>
                We may also collect data about the device you are using to
                access our website. This data may include the device type,
                operating system, unique device identifiers, device settings,
                and geo-location data. What we collect can depend on the
                individual settings of your device and software. We recommend
                checking the policies of your device manufacturer or software
                provider to learn what information they make available to us.
              </p>
              <h4>Personal information</h4>
              <p>We may ask for personal information, such as your:</p>
              <ul>
                <li>Name</li>
                <li>Email</li>
                <li>Phone/Mobile Number</li>
                <li>Home/Mailing Address</li>
              </ul>
              <p>
                This data is considered “identifying information”, as it can
                personally identify you. We only request personal information
                relevant to providing you with a service, and only use it to
                help provide or improve this service.
              </p>
              <h4>How we collect information</h4>
              <p>
                We collect information by fair and lawful means, with your
                knowledge and consent. We also let you know why we’re collecting
                it and how it will be used. You are free to refuse our request
                for this information, with the understanding that we may be
                unable to provide you with some of your desired services without
                it.
              </p>
              <h4>Use of informations</h4>
              <p>
                We may use a combination of identifying and non-identifying
                information to understand who our visitors are, how they use our
                services, and how we may improve their experience of our website
                in future. We do not disclose the specifics of this information
                publicly, but may share aggregated and anonymised versions of
                this information, for example, in website and customer usage
                trend reports. We may use your personal details to contact you
                with updates about our website and services, along with
                promotional content that we believe may be of interest to you.
                If you wish to opt out of receiving promotional content, you can
                follow the “unsubscribe” instructions provided alongside any
                promotional correspondence from us.
              </p>
              <h3>Data processing and storage</h3>
              <p>
                The personal information we collect is stored and processed in
                the United Kingdom, or where we or our partners, affiliates and
                third-party providers maintain facilities. We only transfer data
                within jurisdictions subject to data protection laws that
                reflect our commitment to protecting the privacy of our users.
                We only retain personal information for as long as necessary to
                provide a service, or to improve our services in future. While
                we retain this data, we will protect it within commercially
                acceptable means to prevent loss and theft, as well as
                unauthorised access, disclosure, copying, use or modification.
                That said, we advise that no method of electronic transmission
                or storage is 100% secure, and cannot guarantee absolute data
                security. If you request your personal information be deleted,
                or where your personal information becomes no longer relevant to
                our operations, we will erase it from our system within a
                reasonable timeframe.
              </p>
              <h4>Cookies</h4>
              <p>
                We use “cookies” to collect information about you and your
                activity across our site. A cookie is a small piece of data that
                our website stores on your computer, and accesses each time you
                visit, so we can understand how you use our site. This helps us
                serve you content based on preferences you have specified.
                Please refer to our Cookie Policy for more information.
              </p>
              <h3>Third-party access to information</h3>
              <p>We use third-party services for:</p>
              <ul>
                <li>Analytics tracking</li>
                <li>User authentication</li>
                <li>Advertising and promotion</li>
                <li>Content marketing</li>
                <li>Email marketing</li>
              </ul>
              <p>
                These services may access our data solely for the purpose of
                performing specific tasks on our behalf. We do not share any
                personally identifying information with them without your
                explicit consent. We do not give them permission to disclose or
                use any of our data for any other purpose.
              </p>
              <p>
                We may, from time to time, allow limited access to our data by
                external consultants and agencies for the purpose of analysis
                and service improvement. This access is only permitted for as
                long as necessary to perform a specific function. We only work
                with external agencies whose privacy policies align with ours.
              </p>
              <p>
                We will refuse government and law enforcement requests for data
                if we believe a request is too broad or unrelated to its stated
                purpose. However, we may cooperate if we believe the requested
                information is necessary and appropriate to comply with legal
                process, to protect our own rights and property, to protect the
                safety of the public and any person, to prevent a crime, or to
                prevent what we reasonably believe to be illegal, legally
                actionable, or unethical activity. We do not otherwise share or
                supply personal information to third parties. We do not sell or
                rent your personal information to marketers or third parties.
              </p>
              <h3>Limits of our policy</h3>
              <p>
                At our discretion, we may change our privacy policy to reflect
                current acceptable practices. We will take reasonable steps to
                let users know about changes via our website. If you are a
                registered user on https://www.gibraltar.gov.gi/, we will notify
                you using the contact details saved in your account. Your
                continued use of this site after any changes to this policy will
                be regarded as acceptance of our practices around privacy and
                personal information.
              </p>
              <h3>Your rights and responsibilities</h3>
              <p>
                As our user, you have the right to be informed about how your
                data is collected and used. You are entitled to know what data
                we collect about you, and how it is processed. You are entitled
                to correct and update any personal information about you, and to
                request this information be deleted. You may amend or remove
                your account information at any time, using the tools provided
                in your account control panel. You are entitled to restrict or
                object to our use of your data, while retaining the right to use
                your personal information for your own purposes. You have the
                right to opt out of data about you being used in decisions based
                solely on automated processing.
              </p>
              <p>
                Feel free to contact us if you have any concerns or questions
                about how we handle your data and personal information.
              </p>
              <h6>HM Government of Gibraltar</h6>
              <span>info@gibraltar.gov.gi</span>
              <h6>HM Government of Gibraltar Data Protection Officer</h6>
              <span>
                Gibraltar Regulatory Authority <br />
                info@gra.gi
              </span>

              <p>This policy is effective as of 3 April 2019.</p>
            </Col>
          </Row>
        </div>
      </section>
    </Layout>
  );
}

export default Privacy;
